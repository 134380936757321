/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Ziel dieses Videos:"), " Vertrauen in ein Produkt schaffen. Wir zeigen, warum das Surfbrett seinen Preis wert ist und bauen das Image einer authentischen Marke auf, die ihre eigenen Werte mit Elan verfolgt."), "\n", React.createElement(_components.p, null, "Im Video erlebt der Betrachter den Alltag von Martin, teilt seine Emotionen und baut so eine Beziehung zu Martin, der Marke Treemboards und dem Produkt auf."), "\n", React.createElement(_components.p, null, "Seine Produkte und mehr Informationen findet man auf Martins Webseite: ", React.createElement(_components.a, {
    href: "http://www.treemboards.de",
    title: "Treemboards Website"
  }, "www.treemboards.de")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
